export const modelVertices = [
  [0, 2.5, -0.5],
  [0.097545, 0, -0.490393],
  [0, 0, -0.5],
  [0.097545, 2.5, -0.490393],
  [0.191342, 0, -0.46194],
  [0.097545, 0, -0.490393],
  [0.191342, 2.5, -0.46194],
  [0.277785, 0, -0.415735],
  [0.191342, 0, -0.46194],
  [0.277785, 2.5, -0.415735],
  [0.353553, 0, -0.353553],
  [0.277785, 0, -0.415735],
  [0.353553, 2.5, -0.353553],
  [0.415735, 0, -0.277785],
  [0.353553, 0, -0.353553],
  [0.415735, 2.5, -0.277785],
  [0.46194, 0, -0.191342],
  [0.415735, 0, -0.277785],
  [0.46194, 2.5, -0.191342],
  [0.490393, 0, -0.097545],
  [0.46194, 0, -0.191342],
  [0.490393, 2.5, -0.097545],
  [0.5, 0, 0],
  [0.490393, 0, -0.097545],
  [0.5, 2.5, 0],
  [0.490393, 0, 0.097545],
  [0.5, 0, 0],
  [0.490393, 2.5, 0.097545],
  [0.46194, 0, 0.191342],
  [0.490393, 0, 0.097545],
  [0.46194, 2.5, 0.191342],
  [0.415735, 0, 0.277785],
  [0.46194, 0, 0.191342],
  [0.415735, 2.5, 0.277785],
  [0.353553, 0, 0.353553],
  [0.415735, 0, 0.277785],
  [0.353553, 2.5, 0.353553],
  [0.277785, 0, 0.415735],
  [0.353553, 0, 0.353553],
  [0.277785, 2.5, 0.415735],
  [0.191342, 0, 0.46194],
  [0.277785, 0, 0.415735],
  [0.191342, 2.5, 0.46194],
  [0.097545, 0, 0.490393],
  [0.191342, 0, 0.46194],
  [0.097545, 2.5, 0.490393],
  [0, 0, 0.5],
  [0.097545, 0, 0.490393],
  [0, 2.5, 0.5],
  [-0.097545, 0, 0.490393],
  [0, 0, 0.5],
  [-0.097545, 2.5, 0.490393],
  [-0.191342, 0, 0.46194],
  [-0.097545, 0, 0.490393],
  [-0.191342, 2.5, 0.46194],
  [-0.277785, 0, 0.415735],
  [-0.191342, 0, 0.46194],
  [-0.277785, 2.5, 0.415735],
  [-0.353554, 0, 0.353553],
  [-0.277785, 0, 0.415735],
  [-0.353554, 2.5, 0.353553],
  [-0.415735, 0, 0.277785],
  [-0.353554, 0, 0.353553],
  [-0.415735, 2.5, 0.277785],
  [-0.46194, 0, 0.191341],
  [-0.415735, 0, 0.277785],
  [-0.46194, 2.5, 0.191341],
  [-0.490393, 0, 0.097545],
  [-0.46194, 0, 0.191341],
  [-0.490393, 2.5, 0.097545],
  [-0.5, 0, 0],
  [-0.490393, 0, 0.097545],
  [-0.5, 2.5, 0],
  [-0.490393, 0, -0.097546],
  [-0.5, 0, 0],
  [-0.490393, 2.5, -0.097546],
  [-0.46194, 0, -0.191342],
  [-0.490393, 0, -0.097546],
  [-0.46194, 2.5, -0.191342],
  [-0.415734, 0, -0.277786],
  [-0.46194, 0, -0.191342],
  [-0.415734, 2.5, -0.277786],
  [-0.353553, 0, -0.353554],
  [-0.415734, 0, -0.277786],
  [-0.353553, 2.5, -0.353554],
  [-0.277785, 0, -0.415735],
  [-0.353553, 0, -0.353554],
  [-0.277785, 2.5, -0.415735],
  [-0.191341, 0, -0.46194],
  [-0.277785, 0, -0.415735],
  [-0.46194, 2.5, -0.191342],
  [-0.191342, 2.5, 0.46194],
  [0.46194, 2.5, 0.191342],
  [-0.191341, 2.5, -0.46194],
  [-0.097544, 0, -0.490393],
  [-0.191341, 0, -0.46194],
  [-0.097544, 2.5, -0.490393],
  [0, 0, -0.5],
  [-0.097544, 0, -0.490393],
  [0.490393, 0, -0.097545],
  [0.097545, 0, 0.490393],
  [-0.490393, 0, 0.097545],
  [0.097545, 2.5, -0.490393],
  [0.191342, 2.5, -0.46194],
  [0.277785, 2.5, -0.415735],
  [0.353553, 2.5, -0.353553],
  [0.415735, 2.5, -0.277785],
  [0.46194, 2.5, -0.191342],
  [0.490393, 2.5, -0.097545],
  [0.5, 2.5, 0],
  [0.490393, 2.5, 0.097545],
  [0.46194, 2.5, 0.191342],
  [0.415735, 2.5, 0.277785],
  [0.353553, 2.5, 0.353553],
  [0.277785, 2.5, 0.415735],
  [0.191342, 2.5, 0.46194],
  [0.097545, 2.5, 0.490393],
  [0, 2.5, 0.5],
  [-0.097545, 2.5, 0.490393],
  [-0.191342, 2.5, 0.46194],
  [-0.277785, 2.5, 0.415735],
  [-0.353554, 2.5, 0.353553],
  [-0.415735, 2.5, 0.277785],
  [-0.46194, 2.5, 0.191341],
  [-0.490393, 2.5, 0.097545],
  [-0.5, 2.5, 0],
  [-0.490393, 2.5, -0.097546],
  [-0.46194, 2.5, -0.191342],
  [-0.415734, 2.5, -0.277786],
  [-0.353553, 2.5, -0.353554],
  [-0.277785, 2.5, -0.415735],
  [-0.191341, 2.5, -0.46194],
  [0.191342, 2.5, -0.46194],
  [0.097545, 2.5, -0.490393],
  [0, 2.5, -0.5],
  [-0.097544, 2.5, -0.490393],
  [-0.191341, 2.5, -0.46194],
  [-0.277785, 2.5, -0.415735],
  [-0.353553, 2.5, -0.353554],
  [-0.415734, 2.5, -0.277786],
  [-0.490393, 2.5, -0.097546],
  [-0.5, 2.5, 0],
  [-0.490393, 2.5, 0.097545],
  [-0.46194, 2.5, 0.191341],
  [-0.415735, 2.5, 0.277785],
  [-0.353554, 2.5, 0.353553],
  [-0.277785, 2.5, 0.415735],
  [-0.097545, 2.5, 0.490393],
  [0, 2.5, 0.5],
  [0.097545, 2.5, 0.490393],
  [0.191342, 2.5, 0.46194],
  [0.277785, 2.5, 0.415735],
  [0.353553, 2.5, 0.353553],
  [0.415735, 2.5, 0.277785],
  [0.490393, 2.5, 0.097545],
  [0.5, 2.5, 0],
  [0.490393, 2.5, -0.097545],
  [0.46194, 2.5, -0.191342],
  [0.415735, 2.5, -0.277785],
  [0.353553, 2.5, -0.353553],
  [0.277785, 2.5, -0.415735],
  [-0.097544, 2.5, -0.490393],
  [0, 2.5, -0.5],
  [-0.097544, 0, -0.490393],
  [0, 0, -0.5],
  [0.097545, 0, -0.490393],
  [0.191342, 0, -0.46194],
  [0.277785, 0, -0.415735],
  [0.353553, 0, -0.353553],
  [0.415735, 0, -0.277785],
  [0.46194, 0, -0.191342],
  [0.5, 0, 0],
  [0.490393, 0, 0.097545],
  [0.46194, 0, 0.191342],
  [0.415735, 0, 0.277785],
  [0.353553, 0, 0.353553],
  [0.277785, 0, 0.415735],
  [0.191342, 0, 0.46194],
  [0, 0, 0.5],
  [-0.097545, 0, 0.490393],
  [-0.191342, 0, 0.46194],
  [-0.277785, 0, 0.415735],
  [-0.353554, 0, 0.353553],
  [-0.415735, 0, 0.277785],
  [-0.46194, 0, 0.191341],
  [-0.5, 0, 0],
  [-0.490393, 0, -0.097546],
  [-0.46194, 0, -0.191342],
  [-0.415734, 0, -0.277786],
  [-0.353553, 0, -0.353554],
  [-0.277785, 0, -0.415735],
  [-0.191341, 0, -0.46194],
  [-2, -0.4, 2],
  [-2, 0, -2],
  [-2, -0.4, -2],
  [-2, 0, 2],
  [2, 0, -2],
  [-2, 0, -2],
  [2, 0, 2],
  [2, -0.4, -2],
  [2, 0, -2],
  [2, -0.4, 2],
  [-2, -0.4, -2],
  [2, -0.4, -2],
  [2, 0, -2],
  [-2, -0.4, -2],
  [-2, 0, -2],
  [-2, 0, 2],
  [2, -0.4, 2],
  [2, 0, 2],
  [-2, 0, 2],
  [2, 0, 2],
  [2, -0.4, 2],
  [-2, -0.4, 2],
  [2, -0.4, -2],
  [-2, -0.4, 2],
  [-2, 4, -2],
  [-2, -0.4, -2.4],
  [-2, 4, -2.4],
  [2, -0.4, -2.4],
  [-2, -0.4, -2.4],
  [2, 4, -2.4],
  [2, -0.4, -2.4],
  [2, 4, -2],
  [-2, -0.4, -2],
  [2, -0.4, -2],
  [2, -0.4, -2.4],
  [-2, -0.4, -2.4],
  [-2, 4, -2.4],
  [2, 4, -2],
  [2, 4, -2.4],
  [-2, 4, -2.4],
  [2, 4, -2.4],
  [2, 4, -2],
  [-2, 4, -2],
  [-2, 4, -2],
];

export const modelNormals = [
  [0.098, 0, -0.9952],
  [0.098, 0, -0.9952],
  [0.098, 0, -0.9952],
  [0.2903, 0, -0.9569],
  [0.2903, 0, -0.9569],
  [0.2903, 0, -0.9569],
  [0.4714, 0, -0.8819],
  [0.4714, 0, -0.8819],
  [0.4714, 0, -0.8819],
  [0.6344, 0, -0.773],
  [0.6344, 0, -0.773],
  [0.6344, 0, -0.773],
  [0.773, 0, -0.6344],
  [0.773, 0, -0.6344],
  [0.773, 0, -0.6344],
  [0.8819, 0, -0.4714],
  [0.8819, 0, -0.4714],
  [0.8819, 0, -0.4714],
  [0.9569, 0, -0.2903],
  [0.9569, 0, -0.2903],
  [0.9569, 0, -0.2903],
  [0.9952, 0, -0.098],
  [0.9952, 0, -0.098],
  [0.9952, 0, -0.098],
  [0.9952, 0, 0.098],
  [0.9952, 0, 0.098],
  [0.9952, 0, 0.098],
  [0.9569, 0, 0.2903],
  [0.9569, 0, 0.2903],
  [0.9569, 0, 0.2903],
  [0.8819, 0, 0.4714],
  [0.8819, 0, 0.4714],
  [0.8819, 0, 0.4714],
  [0.773, 0, 0.6344],
  [0.773, 0, 0.6344],
  [0.773, 0, 0.6344],
  [0.6344, 0, 0.773],
  [0.6344, 0, 0.773],
  [0.6344, 0, 0.773],
  [0.4714, 0, 0.8819],
  [0.4714, 0, 0.8819],
  [0.4714, 0, 0.8819],
  [0.2903, 0, 0.9569],
  [0.2903, 0, 0.9569],
  [0.2903, 0, 0.9569],
  [0.098, 0, 0.9952],
  [0.098, 0, 0.9952],
  [0.098, 0, 0.9952],
  [-0.098, 0, 0.9952],
  [-0.098, 0, 0.9952],
  [-0.098, 0, 0.9952],
  [-0.2903, 0, 0.9569],
  [-0.2903, 0, 0.9569],
  [-0.2903, 0, 0.9569],
  [-0.4714, 0, 0.8819],
  [-0.4714, 0, 0.8819],
  [-0.4714, 0, 0.8819],
  [-0.6344, 0, 0.773],
  [-0.6344, 0, 0.773],
  [-0.6344, 0, 0.773],
  [-0.773, 0, 0.6344],
  [-0.773, 0, 0.6344],
  [-0.773, 0, 0.6344],
  [-0.8819, 0, 0.4714],
  [-0.8819, 0, 0.4714],
  [-0.8819, 0, 0.4714],
  [-0.9569, 0, 0.2903],
  [-0.9569, 0, 0.2903],
  [-0.9569, 0, 0.2903],
  [-0.9952, 0, 0.098],
  [-0.9952, 0, 0.098],
  [-0.9952, 0, 0.098],
  [-0.9952, 0, -0.098],
  [-0.9952, 0, -0.098],
  [-0.9952, 0, -0.098],
  [-0.9569, 0, -0.2903],
  [-0.9569, 0, -0.2903],
  [-0.9569, 0, -0.2903],
  [-0.8819, 0, -0.4714],
  [-0.8819, 0, -0.4714],
  [-0.8819, 0, -0.4714],
  [-0.773, 0, -0.6344],
  [-0.773, 0, -0.6344],
  [-0.773, 0, -0.6344],
  [-0.6344, 0, -0.773],
  [-0.6344, 0, -0.773],
  [-0.6344, 0, -0.773],
  [-0.4714, 0, -0.8819],
  [-0.4714, 0, -0.8819],
  [-0.4714, 0, -0.8819],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [-0.2903, 0, -0.9569],
  [-0.2903, 0, -0.9569],
  [-0.2903, 0, -0.9569],
  [-0.098, 0, -0.9952],
  [-0.098, 0, -0.9952],
  [-0.098, 0, -0.9952],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0.098, 0, -0.9952],
  [0.2903, 0, -0.9569],
  [0.4714, 0, -0.8819],
  [0.6344, 0, -0.773],
  [0.773, 0, -0.6344],
  [0.8819, 0, -0.4714],
  [0.9569, 0, -0.2903],
  [0.9952, 0, -0.098],
  [0.9952, 0, 0.098],
  [0.9569, 0, 0.2903],
  [0.8819, 0, 0.4714],
  [0.773, 0, 0.6344],
  [0.6344, 0, 0.773],
  [0.4714, 0, 0.8819],
  [0.2903, 0, 0.9569],
  [0.098, 0, 0.9952],
  [-0.098, 0, 0.9952],
  [-0.2903, 0, 0.9569],
  [-0.4714, 0, 0.8819],
  [-0.6344, 0, 0.773],
  [-0.773, 0, 0.6344],
  [-0.8819, 0, 0.4714],
  [-0.9569, 0, 0.2903],
  [-0.9952, 0, 0.098],
  [-0.9952, 0, -0.098],
  [-0.9569, 0, -0.2903],
  [-0.8819, 0, -0.4714],
  [-0.773, 0, -0.6344],
  [-0.6344, 0, -0.773],
  [-0.4714, 0, -0.8819],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [-0.2903, 0, -0.9569],
  [-0.098, 0, -0.9952],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [-1, 0, 0],
  [-1, 0, 0],
  [-1, 0, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [1, 0, 0],
  [1, 0, 0],
  [1, 0, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, -1, 0],
  [0, 0, -1],
  [0, 0, -1],
  [0, 0, -1],
  [0, 0, 1],
  [0, 0, 1],
  [0, 0, 1],
  [-1, 0, 0],
  [0, 1, 0],
  [1, 0, 0],
  [0, -1, 0],
  [0, 0, -1],
  [0, 0, 1],
  [-1, 0, 0],
  [-1, 0, 0],
  [0, 0, -1],
  [0, 0, -1],
  [0, 0, -1],
  [1, 0, 0],
  [1, 0, 0],
  [0, 0, 1],
  [0, 0, 1],
  [0, 0, 1],
  [0, -1, 0],
  [0, -1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [0, 1, 0],
  [-1, 0, 0],
  [0, 0, -1],
  [1, 0, 0],
  [0, 0, 1],
  [0, 1, 0],
];

export const modelIndices = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
  79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97,
  98, 99, 100, 101, 0, 102, 1, 3, 103, 4, 6, 104, 7, 9, 105, 10, 12, 106, 13,
  15, 107, 16, 18, 108, 19, 21, 109, 22, 24, 110, 25, 27, 111, 28, 30, 112, 31,
  33, 113, 34, 36, 114, 37, 39, 115, 40, 42, 116, 43, 45, 117, 46, 48, 118, 49,
  51, 119, 52, 54, 120, 55, 57, 121, 58, 60, 122, 61, 63, 123, 64, 66, 124, 67,
  69, 125, 70, 72, 126, 73, 75, 127, 76, 78, 128, 79, 81, 129, 82, 84, 130, 85,
  87, 131, 88, 132, 133, 134, 134, 135, 132, 135, 136, 132, 136, 137, 138, 138,
  139, 90, 90, 140, 141, 141, 142, 90, 142, 143, 90, 143, 144, 91, 144, 145, 91,
  145, 146, 91, 91, 147, 148, 148, 149, 150, 150, 151, 152, 152, 153, 92, 92,
  154, 155, 155, 156, 92, 156, 157, 92, 157, 158, 159, 159, 160, 132, 136, 138,
  132, 138, 90, 132, 91, 148, 92, 148, 150, 92, 150, 152, 92, 157, 159, 92, 159,
  132, 92, 90, 143, 91, 132, 90, 92, 93, 161, 94, 96, 162, 97, 163, 164, 165,
  165, 166, 167, 167, 168, 169, 169, 170, 167, 170, 99, 167, 99, 171, 172, 172,
  173, 99, 173, 174, 99, 174, 175, 100, 175, 176, 100, 176, 177, 100, 100, 178,
  179, 179, 180, 181, 181, 182, 183, 183, 184, 101, 101, 185, 186, 186, 187,
  188, 188, 189, 163, 189, 190, 163, 190, 191, 163, 163, 165, 167, 100, 179,
  101, 179, 181, 101, 181, 183, 101, 101, 186, 163, 186, 188, 163, 163, 167, 99,
  99, 174, 100, 163, 99, 101, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201,
  202, 203, 204, 205, 206, 207, 208, 209, 192, 210, 193, 195, 211, 196, 198,
  212, 199, 201, 213, 202, 204, 214, 205, 207, 215, 208, 216, 217, 194, 218,
  219, 220, 221, 199, 222, 223, 224, 225, 226, 202, 227, 228, 229, 230, 216,
  231, 217, 218, 232, 219, 221, 233, 199, 223, 234, 224, 226, 203, 202, 228,
  235, 229,
];
